<template>
    <div class="p-relative">

        <Toolbar :isList="true" :menu="fakeMenu" v-on:toolbar-button-click="onToolbarClick" />
        
    </div>
</template>

<script>
import i18n from '@/i18n';
import { mapActions } from 'vuex';

export default {
    name: "Home",
    data: () => ({
    }),
    computed: {
        fakeMenu() {
            return [
                {
                    Action: "Refresh",
                    Icon: "refresh",
                    Items: null,
                    Text: i18n.t("Обновить"),
                    ToolTip: i18n.t("Обновить_форму"),
                    Type: null
                }
            ]
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async initialize() {
            
        },
        async onToolbarClick (event, button) {
            if (button.Action == "Refresh")
                this.initialize();
        }
    }
}
</script>